/* minisite */

@import "../css/clear.css";
@import "../css/utils.css";
@import "../css/bootstrap-grid.min.css";
@import "../css/bootstrap.css";

body, html {
  /* min-width: 320px; */
  min-height: 100%;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  /* font-family: 'DFT', sans-serif; */
  font-size: 1.6rem;
  color: #000000;
  line-height: 1.6;
  background-color: #F2f2f2;
  padding-top: 80px;
  padding-bottom: 20px;
}

@media screen and (max-width: 576px) {
  body {
    word-break: break-all;
  }
}

:root {
  /* variables */
  --primary-color: #21618C;
  --secondary-color: #21618C;
  --screen-width: 100vw;
  --screen-height: 100vh;
  --screen-ratio: calc(var(--screen-width) / var(--screen-height));
}

* {
  box-sizing: border-box;
}

/*------------------------------------------------------- 
placeholder 
--------------------------------------------------------*/

.placeholder-loading {
  animation: placeHolderLoading 3s linear infinite;
  color: transparent!important;
  border: none!important;
  border-radius: 5px;
}

@keyframes placeHolderLoading {
  0% {
    background-color: white
  }
  50% {
    background-color: rgb(242, 242, 242)
  }
  100% {
    background-color: white
  }
}

/*------------------------------------------------------- 
analysis page
--------------------------------------------------------*/

.chart-label {
  white-space: pre-line;
}

/*------------------------------------------------------- 
event log  page
--------------------------------------------------------*/

.led.green {
  background-image: -webkit-linear-gradient(top, #13fB04 0%, #58e343 50%, #ADED99 100%);
}

.led.orange {
  background-image: -webkit-linear-gradient(top, #f9a004 0%, #e0ac45 50%, #ead698 100%);
}

.led.red {
  background-image: -webkit-linear-gradient(top, #fb1304 0%, #e35843 50%, #edad99 100%);
}

.led {
  border-radius: 5px;
  width: 10px;
  height: 10px;
  box-shadow: 0px 0px 3px black;
  margin: 5px;
  zoom: 1.25
}

.led:after {
  display: block;
  content: '';
  margin-left: 1px;
  margin-right: 1px;
  width: 8px;
  height: 6px;
  -webkit-border-top-right-radius: 4px 3px;
  -webkit-border-top-left-radius: 4px 3px;
  -webkit-border-bottom-right-radius: 4px 3px;
  -webkit-border-bottom-left-radius: 4px 3px;
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.2) 100%);
}

/*------------------------------------------------------- 
event log  page
--------------------------------------------------------*/

#logtable tr {
  border: 1px solid #BCBCBC;
}

/*------------------------------------------------------- 
task page
--------------------------------------------------------*/

.task-status {
  text-align: center;
  background-color: white;
  display: table;
  width: 100%;
}

.task-status-ttl img {
  width: 100px;
}

.task-status-ttl::after {
  content: "";
  position: absolute;
  border-right: 1px solid #ccc;
  height: 100%;
  right: 0px;
  top: 0px
}

.task-status-desciption {}

.task-status-ttl, .task-status-desciption {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  position: relative;
  width: 50%;
}

@media screen and (max-width: 576px) {
  .task-status-ttl, .task-status-desciption {
    display: block;
    width: auto;
  }
  .task-status-ttl::after {
    display: none;
  }
}

.task-history .col img {
  width: 50px;
}

@media screen and (max-width: 576px) {
  .task-history .col:nth-child(1) {
    margin-bottom: 10px;
  }
}

#record-table {
  font-size: 12px;
  text-align: left;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .table-container {
    width: 720px;
  }
}

#record-table th {
  padding: 3px;
  font-weight: bold;
}

#record-table tbody tr {
  border-top: 1px solid #BCBCBC;
}

#record-table td {
  padding: 5px
}

/*------------------------------------------------------- 
api page
--------------------------------------------------------*/

.api-box {
  word-break: initial;
  /* height: 0; */
  /* transform : scaleY(0);
  transform-origin: top; */
  /* padding : 20px; */
  border: 1px solid #003f6c;
  transition: all .3s;
  font-size: 12px;
}

.api-box.get {
  background-color: #AED6F1;
}

.api-box.post {
  background-color: #F9E79F;
}

.api-header {
  /* transform : scaleY(1); */
  display: flex;
  border-bottom: 1px solid #003f6c;
}

.api-tag {
  color: #fff;
  width: 50px;
  padding: 5px;
  text-align: center;
}

.api-box.get .api-tag {
  background-color: #21618C;
}

.api-box.post .api-tag {
  background-color: #D68910;
}

.api-box.get h5 {
  font-size: 14px;
  color: #21618C;
  font-weight: bold;
}

.api-box.post h5 {
  font-size: 14px;
  color: #D68910;
  font-weight: bold;
}

.api-box-st1 {
  color: #7D7D7D;
  border-right: 1px solid #ccc;
  padding: 5px 5px 5px 0px;
}

.api-box-st2 {
  padding: 5px;
}

.api-responsetype-select {}

.api-response-example {
  width: 100%;
  height: 200px;
  background-color: beige;
  resize: vertical;
  overflow: auto;
}

.api-table-container {
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 20px;
}

#api-table {
  border: none;
  min-width: 500px;
}

#api-table td {
  padding: 3px 3px 10px 3px;
}

#api-table th {
  color: #7D7D7D;
  padding: 3px;
  font-weight: 400;
  border-bottom: 1px solid #BCBCBC;
}

.api-parameter-name {
  width: 90px;
}

.api-parameter-value {
  width: 200px;
}

.api-parameter-value>input {
  width: 90%;
  vertical-align: text-top;
  font-size: inherit;
}

.api-parameter-description {
  max-width: 100px;
}

/*------------------------------------------------------- 
header
--------------------------------------------------------*/

#header {
  background-color: white;
  min-height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  padding: 0px 10px;
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  z-index: 9999;
}

.header-logo {
  float: left;
  margin-top: 5px;
  /* display: inline-block */
}

.header-logo img {
  max-width: 50px;
  display: block;
}

.header-menu {
  position: absolute;
  flex-direction: column;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  padding: 12px 5px;
}

.header-menu span {
  width: 100%;
  height: 3px;
  background-color: #21618C;
  border-radius: 2px;
  transition: all .3s;
  opacity: 1;
}

.header-menu.active span:nth-of-type(1) {
  -webkit-transform: translateY(12px) rotate(-45deg);
  transform: translateY(9px) rotate(-45deg);
}

.header-menu.active span:nth-of-type(2) {
  transform: translateX(5px);
  opacity: 0;
}

.header-menu.active span:nth-of-type(3) {
  -webkit-transform: rotate(45deg);
  transform: translateY(-13px) rotate(45deg);
}

.menu-trigger {
  display: table-cell;
  vertical-align: middle;
}

.nav-dropmenu {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
}

.nav-dropmenu li {
  display: inline-block;
}

.nav-dropmenu li button {
  background-color: #fff;
  border-bottom: none;
  border-left: #F2f2f2 1px solid;
  border-right: #F2f2f2 1px solid;
  min-width: 60px;
  height: 60px;
  color: #000;
  padding: 0 10px;
}



@media (hover: hover) {
  .nav-dropmenu li button:hover {

    background-color: rgba(0, 0, 0, 0.1);
  }
}

.nav-dropmenu * {
  height: 100%;
}

.header-menu {
  display: none;
}

.nav-dropmenu .selected {
  border-bottom: #457BCD 3px solid;
  background-color: transparent !important;
}

@media screen and (max-width: 576px) {
  .nav-dropmenu .selected {
    border-bottom: 1px solid #b9e9ff;
    background-color: #004d99!important
  }
  .nav-dropmenu {
    visibility: hidden;
    opacity: 0;
    transition: 0s;
    position: fixed;
    left: 0;
    right: 0;
    top: 60px;
    display: block;
    width: 100%;
    z-index: 99;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    -moz-transform: translateY(-40%);
    -webkit-transform: translateY(-40%);
    transform: translateY(-40%);
  }
  .nav-dropmenu.animation {
    visibility: visible;
    opacity: 1;
    transition: .3s;
    -moz-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  .nav-dropmenu li {
    display: block;
  }
  .header-menu {
    top: 5px;
    display: flex;
  }
  .nav-dropmenu li button {
    text-align: center;
    margin: 0px;
    padding: 0px;
    width: 100%;
    color: #fff;
    background-color: #004d99;
    border-bottom: 1px solid #b9e9ff;
  }
  .nav-dropmenu li button:hover {
    outline: none;
    background-color: #004d99c4
  }
  .nav-dropmenu li button:focus {
    background-color: #004d99
  }
  .nav-dropmenu, .nav-dropmenu ul, .nav-dropmenu li {
    height: auto;
  }
}

/* @media screen and (max-width: 768px) {
  .header-logo {
    max-width: 40px;
  }
} */

/*------------------------------------------------------- 
container
--------------------------------------------------------*/

#container {
  position: relative;
  margin: 0px auto;
}

@media screen and (max-width: 576px) {
  #container {
    padding: 0px 20px;
  }
}

/*------------------------------------------------------- 
section
--------------------------------------------------------*/

.section-title {
  text-indent: 10px;
  margin-bottom: 5px;
  text-align: left!important;
}

/*------------------------------------------------------- 
@inner
--------------------------------------------------------*/

.inner {
  margin: 0 auto;
}

.inner-350 {
  width: 350px;
}

.inner-540 {
  width: 540px;
}

.inner-720 {
  width: 720px;
}

.inner-1020 {
  width: 1020px;
}

.inner-1200 {
  width: 1200px;
}

.inner-p20 {
  padding: 20px;
}

.inner-p40 {
  padding: 40px;
}

@media screen and (max-width: 400px) {
  .inner-350 {
    width: auto;
  }
}

@media screen and (max-width: 576px) {
  .inner-540 {
    width: auto;
  }
  .inner-p20 {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .inner-p40 {
    padding: 20px;
  }
  .inner-720 {
    width: auto;
  }
}

@media screen and (max-width: 1020px) {
  .inner-1020 {
    width: auto;
  }
}

@media screen and (max-width: 1200px) {
  .inner-1200 {
    width: auto;
  }
}

/*------------------------------------------------------- 
logo
--------------------------------------------------------*/

.logo-mini {}

.logo-small {}

.logo-medium {
  max-width: 80px;
}

.logo-large {}

/*------------------------------------------------------- 
modal
--------------------------------------------------------*/

.modal {
  background-color: rgba(91, 112, 131, 0.4);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 9999;
}

.modal-content {
  position: relative;
  top: 10%;
  background-color: #fff;
  /* left: 0;
  right: 0; */
  margin: 0 auto;
  width: 80vw;
  max-width: 500px;
  max-height: 500px;
}

.modal-content ul img {
  width: 30px;
}

/*------------------------------------------------------- 
list
--------------------------------------------------------*/

ul.list-unstyled li {
  padding-left: 0;
  list-style: none;
}

ul {
  list-style-position: inside;
}

.list-row {
  display: flex;
  flex-wrap: wrap;
}

.list-header {
  flex: 1
}

.list-tail {
  width: auto;
}

.list-btn:focus, .list-btn:hover {
  color: #464646;
}

@media screen and (max-width: 576px) {
  .list-tail {
    width: 100%;
    text-align: right;
  }
}

/*------------------------------------------------------- 
table 
--------------------------------------------------------*/

table {
  width: 100%;
  border-collapse: collapse;
}

/* 
table, th, td {
  border: 1px solid #BCBCBC;
} */

/*------------------------------------------------------- 
border divider 
--------------------------------------------------------*/

.divider {
  border: 1px solid #BCBCBC;
}

/*------------------------------------------------------- 
shadow
--------------------------------------------------------*/

.box-shadow {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3)
}

/* .inner-shadow {
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3)
} */

/*------------------------------------------------------- 
flex-box
--------------------------------------------------------*/

.flex-box {
  display: flex;
  flex-wrap: wrap;
}

.flex-box-100 {
  width: 100%;
}

.flex-box-50 {
  width: 100%;
}

/* @media screen and (max-width: 768px) {
  .inner-p40 {
    padding: 20px;
  }
} */

@media screen and (min-width: 768px) {
  .flex-box-50 {
    width: 50%;
  }
  .flex-box-100 {
    padding: 0 20px
  }
  .flex-box-50-pl {
    padding-left: 20px;
    padding-right: 10px;
  }
  .flex-box-50-pr {
    padding-left: 10px;
    padding-right: 20px;
  }
}

/* 
@media screen and (min-width: 768px) {
 
} */

/*------------------------------------------------------- 
textarea
--------------------------------------------------------*/

textarea:focus {
  outline: none;
}

/*------------------------------------------------------- 
wrapper
--------------------------------------------------------*/

.wrapper {
  /* width: auto; */
  margin: 0 auto;
  overflow: hidden;
  /* min-width: 360px; */
}

/*------------------------------------------------------- 
heading
--------------------------------------------------------*/

h1 {
  font-size: 2.25em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.75em;
}

h4 {
  font-size: 1.5em;
}

h5 {
  font-size: 1.25em;
}

h1, h2, h3, h4, h5 {
  font-weight: 400;
  word-break: normal;
}

/*------------------------------------------------------- 
@button 
--------------------------------------------------------*/

button {
  font: inherit;
  color: inherit;
  border: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

/* 
button:focus, button:active, .btn:focus, .btn:active {
  outline: none;
  background-color: rgba(0, 0, 0,.1) !important;
} */

/* 
@media (hover: hover) {
  button:hover, .btn:hover {
    outline: none;
    background-color: rgba(0, 0, 0, 0.1);
  }
}  */

/* .btn:active{

  background-color: rgb(212, 212, 212)

} */

.btn  {
  font-size: inherit;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  padding: 10px;
  cursor: pointer;
}

/* 
.btn-primary {
  background-color:rgba(54, 162, 235, 1);
  color: black;
} */

.btn-secondary {
  background-color: #004d99;
  color: white;
  border-radius: 10px;
  min-width: 150px;
}

.btn-floating {
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 100%;
  -webkit-box-shadow: 7px 8px 15px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 8px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 7px 8px 15px -10px rgba(0, 0, 0, 0.75);
}

.btn-radio {
  width: 20px;
  height: 20px;
}

.btn-abs-left {
  width: 15%;
  top: 10px;
  left: 10px;
  position: absolute;
  cursor: pointer;
}

.btn-abs-right {
  width: 15%;
  cursor: pointer;
  top: 10px;
  right: 10px;
  position: absolute;
}

/*------------------------------------------------------- 
@input form
--------------------------------------------------------*/

input[type="text"].form-control, input[type="password"].form-control, input[type="date"].form-control, input[type="datetime"].form-control, input[type="email"].form-control, input[type="number"].form-control, input[type="search"].form-control, input[type="tel"].form-control, input[type="time"].form-control, input[type="url"].form-control, select.form-control, textarea.form-control {
  font-size: 16px;
  cursor: pointer;
}

input[type="text"].form-control:focus, input[type="password"].form-control:focus, input[type="date"].form-control:focus, input[type="datetime"].form-control:focus, input[type="email"].form-control:focus, input[type="number"].form-control:focus, input[type="search"].form-control:focus, input[type="tel"].form-control:focus, input[type="time"].form-control:focus, input[type="url"].form-control:focus, select.form-control:focus, textarea.form-control:focus {
  border: 1px solid var(--primary-color);
  /* メインカラー */
  outline: none;
}

input[type="radio"] {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type=number] {
  -moz-appearance: textfield;
}

.form-control {
  margin-bottom: 5px;
  padding: 4px 6px;
  font-size: 16px;
  height: 36px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: none;
  display: block;
  width: 100%;
  -webkit-box-shadow: none;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

/*------------------------------------------------------- 
@floating 
--------------------------------------------------------*/

.floating {
  position: fixed;
  bottom: 40px;
  right: 20px;
}

/*------------------------------------------------------- 
hyperlink a
--------------------------------------------------------*/

a {
  /* text-decoration: none; */
  -webkit-tap-highlight-color: transparent;
  color: inherit;
}

/*------------------------------------------------------- 
footer
--------------------------------------------------------*/

#footer {
  font-family: 'AdobeMingStd';
  text-align: center;
  font-size: 12px;
  /* font-weight: 200; */
}

#footer>div>span {
  cursor: pointer;
  /* font-weight: 200; */
}

/*------------------------------------------------------- 
image img
--------------------------------------------------------*/

img {
  /* display: block; */
  /* user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none; */
  max-width: 100%;
  height: auto;
}

/*------------------------------------------------------- 
@select
--------------------------------------------------------*/

#track-select {
  width: 25%;
  text-align: center;
  max-width: 200px;
}

/*------------------------------------------------------- 
animation
--------------------------------------------------------*/

.gachaing {
  animation: gacha linear 1s infinite
}

@keyframes gacha {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}